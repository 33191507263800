import ValidatedInput from "components/form/ValidatedInput";
import actions from "lib/constants/permissions/actions";
import resourceTypes from "lib/constants/permissions/resourceTypes";
import isAuthorizedForResource from "lib/helpers/permissions/permissionsHelper";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserData } from "redux/user/userSlice";

const EditUserForm = ({ userData, saveUser, deleteUser, permissions, cancel, processingRequest }) => {
  const [email, setEmail] = useState(userData?.email ?? "");
  const [displayName, setDisplayName] = useState(userData?.displayName ?? "");
  const [selectedPermissions, setSelectedPermissions] = useState(userData?.permissions?.map((r) => r.code) ?? []);
  const [hasManagePermission, setHasManagePermission] = useState(false);
  const { data } = useSelector((state) => selectUserData(state));

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => setHasManagePermission(isAuthorizedForResource(resourceTypes.Actions, actions.EditUser, data)), [data]);

  const handlePermissionClick = (e) => {
    selectedPermissions.includes(e.target.id)
      ? setSelectedPermissions(selectedPermissions.filter((x) => x !== e.target.id))
      : setSelectedPermissions([...selectedPermissions, e.target.id]);
  };

  const isPermissionChecked = (r) => selectedPermissions?.includes(r.code);

  const save = () => {
    let errors = {};
    if (!email || !email.trim()) errors.email = "Email is required";
    if (!displayName || !displayName.trim()) errors.displayName = "Display Name is required";
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      saveUser(email, displayName, selectedPermissions);
    }
  };

  if (processingRequest) return <></>;
  return (
    <div className="ampCommonContainer">
      <fieldset disabled={!hasManagePermission}>
        <div id="editUserForm">
          <label className="ampFormLabel" as="legend">
            Email
          </label>

          <ValidatedInput
            className="ampFormControl"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            value={email}
            readOnly={userData}
            error={formErrors.email}
          />

          <label className="ampFormLabel" as="legend">
            Display Name
          </label>
          <ValidatedInput
            className="ampFormControl"
            name="displayName"
            onChange={(e) => setDisplayName(e.target.value)}
            type="text"
            value={displayName}
            error={formErrors.displayName}
          />

          <label className="ampFormLabel" as="legend">
            Permissions
          </label>
          <GroupedPermissions
            permissions={permissions}
            isPermissionChecked={isPermissionChecked}
            handlePermissionClick={handlePermissionClick}
          />

          {hasManagePermission && (
            <div className="buttonDiv">
              <Button size="sm" variant="success" onClick={save}>
                {userData ? "Save Changes" : "Add User"}
              </Button>
              <Button size="sm" variant="primary" onClick={() => cancel()}>
                {"Cancel"}
              </Button>
              {userData && (
                <>
                  <hr></hr>
                  <Button size="sm" variant="danger" onClick={deleteUser}>
                    Delete User
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </fieldset>

      {!hasManagePermission && (
        <div className="ampFlexCenter">
          <Button size="sm" variant="primary" onClick={() => cancel()}>
            {"Return to Users List"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditUserForm;

const GroupedPermissions = ({ permissions, isPermissionChecked, handlePermissionClick }) => {
  const [permissionGroups, setPermissionGroups] = useState();

  // identify groups
  useEffect(() => {
    if (!permissions) return;
    var groups = permissions.map((r) => r.group);

    groups.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    });

    // Move Global Admin / Organisaion Admin to top of list
    groups = [
      groups.find((g) => g === "Global Admin"),
      groups.find((g) => g === "Organisation Admin"),
      ...groups.filter((g) => g !== "Global Admin" && g !== "Organisation Admin"),
    ].filter((x) => x);

    setPermissionGroups(groups);
  }, [permissions]);

  if (!permissionGroups) return <></>;

  const getPermissionabel = (group, permission) => {
    return (
      <>
        <span className="permissionLabel">
          {permission.name} - {permission.description}
        </span>
      </>
    );
  };

  return (
    <div id="permissionsDiv">
      {permissionGroups.map((group) => {
        return (
          <div>
            <span className="ampFormOptionGroupHeading">{group}</span>
            <hr className="m-2" />
            <div className="m-1">
              {permissions
                ?.filter((p) => p.group === group)
                .map((r) => {
                  return (
                    <Form.Check
                      type="checkbox"
                      label={getPermissionabel(group, r)}
                      name="permissions"
                      id={r.code}
                      className="m-2"
                      checked={isPermissionChecked(r)}
                      onChange={(e) => handlePermissionClick(e)}
                      key={r.code}></Form.Check>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
