import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthorizedRoute from "components/route/AuthorizedRoute";
import Sidebar from "components/sidebar/Sidebar";
import Topbar from "components/topbar/Topbar";
import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import Loader from "components/utility/loader/Loader";
import { b2cErrorCodes, inProgressActiveStatuses } from "lib/constants/aad/msalConfig";
import paths from "lib/constants/routing/paths";
import useCurrentUserInfo from "lib/fetch/helpers/useCurrentUserInfo";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "screens/dashboard/Dashboard";
import PageNotFound from "screens/errors/pageNotFound/PageNotFound";
import Logs from "screens/logs/Logs";
import EditOrganisation from "screens/organisationManagement/EditOrganisation";
import OrganisationManagement from "screens/organisationManagement/OrganisationManagement";
import EditUser from "screens/userManagement/EditUser";
import UserManagement from "screens/userManagement/UserManagement";
import Swal from "sweetalert2";
import "./App.css";

function App() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userData, refresh, isFetching, userFetchErrored] = useCurrentUserInfo(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  useEffect(() => {
    // return if already processing an event
    if (inProgressActiveStatuses.includes(inProgress)) return;
    if (isAuthenticated) return refresh();

    // redirect to forget password if setup password token has expired
    if (window.location.href?.includes(b2cErrorCodes.expiredToken)) {
      displayAlert(
        "Link has Expired",
        "You can setup your password by using the <strong>Forgot your Password?</strong> link on the login page.",
        {
          ...alertTypes.confirm,
          confirmButtonText: "Go to Login",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: false,
          onDone: (result) => {
            instance.loginRedirect({});
          },
        }
      );
    }
    // redirect to login screen if not authenticated
    else if (!isAuthenticated) {
      instance.loginRedirect({});
    }
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    if (isAuthenticated && !isFetching && userFetchErrored && inProgress === "none") {
      displayAlert("Error", "An error has occurred. Please try logging out and logging back in again.", {
        ...alertTypes.error,
        confirmButtonText: "Logout",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onDone: (result) => {
          if (result.isConfirmed) instance.logoutRedirect();
        },
      });
    } else if (isAuthenticated && !userFetchErrored && !isFetching) {
      Swal.close();
    }
  }, [inProgress, userFetchErrored, isAuthenticated, isFetching]);

  //prettier-ignore
  const getAuthenticatedScreen = () => {
    return (
      <div>
        <Routes>
         <Route exact path={paths.Default.link} element={<AuthorizedRoute><Dashboard /></AuthorizedRoute>} />
        
          <Route exact path={paths.ViewUsers.link} element={<AuthorizedRoute> <UserManagement /></AuthorizedRoute>} />
          <Route exact path={paths.CreateUser.link} element={<AuthorizedRoute> <EditUser /></AuthorizedRoute>} />
          <Route exact path={paths.ViewUser.link} element={<AuthorizedRoute> <EditUser /></AuthorizedRoute>} />

          <Route exact path={paths.ViewAdminUsers.link} element={<AuthorizedRoute> <UserManagement /></AuthorizedRoute>} />
          <Route exact path={paths.CreateAdminUser.link} element={<AuthorizedRoute> <EditUser /></AuthorizedRoute>} />
          <Route exact path={paths.ViewAdminUser.link} element={<AuthorizedRoute> <EditUser /></AuthorizedRoute>} />

          <Route exact path={paths.ViewOrganisations.link} element={<AuthorizedRoute> <OrganisationManagement /></AuthorizedRoute>} />
          <Route exact path={paths.CreateOrganisation.link} element={<AuthorizedRoute> <EditOrganisation /></AuthorizedRoute>} />
          <Route exact path={paths.ViewOrganisation.link} element={<AuthorizedRoute> <EditOrganisation /></AuthorizedRoute>} />

          <Route exact path={paths.ViewLogs.link} element={<AuthorizedRoute> <Logs /></AuthorizedRoute>} />

          <Route path={"*"} element={<PageNotFound />} />
        </Routes>
      </div>
   
    );
  };

  const displayAwaitingRedirectScreen = () => {
    return (
      <>
        <Loader visible={true} centerAbsoloute={true} size="md" />
      </>
    );
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <ToastContainer />
      <Router>
        <Topbar />
        <Sidebar isSidebarExpanded={setIsSidebarExpanded} />
        <div id="app" className={isSidebarExpanded ? "leftSidebarExpandedMargin" : ""}>
          {isAuthenticated && userData ? getAuthenticatedScreen() : displayAwaitingRedirectScreen()}
        </div>
      </Router>
    </div>
  );
}

export default App;
