import BarChart from "components/statistics/BarChart";
import CountStatistic from "components/statistics/CountStatistic";
import LineChart from "components/statistics/LineChart";
import Map from "components/statistics/map/Map";
import PieChart from "components/statistics/PieChart";
import { displayToast } from "components/utility/alerts/Toast";
import { getStatisticsByOrganisationId } from "lib/fetch/requests/statisticsRequests";
import useFetch from "lib/fetch/useFetch";
import {
  getPinBlocksByPlatformForLastXDays,
  getPinBlocksByPlatformForLastXHours,
  getSessionCountByPlatform,
  getTotalHourlyStatisticMetricByKey,
  getTotalHourlyStatisticMetricsForLastXDays,
  getTotalHourlyStatisticMetricsForLastXHours,
  hourlyStatisticMetrics,
} from "lib/helpers/statistics/hourlyStatistics";
import { ReactComponent as TotalSessionsIcon } from "lib/icons/activity.svg";
import { ReactComponent as SystemErrorIcon } from "lib/icons/alert-triangle.svg";
import { ReactComponent as TotalPinBlocksIcon } from "lib/icons/check-square.svg";
import { ReactComponent as BusinessErrorIcon } from "lib/icons/lock.svg";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import "./Dashboard.css";

const Dashboard = () => {
  const { organisationId } = useParams();

  const [dateFilter, setDateFilter] = useState(1);
  const [dateRange, setDateRange] = useState({
    from: moment().utc().subtract(24, "hours"), //initialise with the last 24 hours filter
    to: moment().utc(),
  });
  const [metrics, setMetrics] = useState({});

  const [statistics, getStatistics, isFetching, error] = useFetch(
    getStatisticsByOrganisationId(organisationId, dateRange.from.toDate(), dateRange.to.toDate())
  );

  useEffect(() => {
    getStatistics();
  }, [dateRange]);

  useEffect(() => {
    let updatedMetrics = {};

    updatedMetrics.totalHourlyStatistics =
      dateFilter > 1
        ? getTotalHourlyStatisticMetricsForLastXDays(statistics, dateFilter)
        : getTotalHourlyStatisticMetricsForLastXHours(statistics, 24);

    updatedMetrics.pinblocksByPlatform =
      dateFilter > 1
        ? getPinBlocksByPlatformForLastXDays(statistics, dateFilter)
        : getPinBlocksByPlatformForLastXHours(statistics, 24);

    updatedMetrics.platformUsage = getSessionCountByPlatform(statistics);

    updatedMetrics.totalSessions = getTotalHourlyStatisticMetricByKey(statistics, hourlyStatisticMetrics.sessionStarted);
    updatedMetrics.totalPinBlocks = getTotalHourlyStatisticMetricByKey(statistics, hourlyStatisticMetrics.pinBlockGenerated);
    updatedMetrics.totalBusinessErrors = getTotalHourlyStatisticMetricByKey(statistics, hourlyStatisticMetrics.businessError);
    updatedMetrics.totalSystemErrors = getTotalHourlyStatisticMetricByKey(statistics, hourlyStatisticMetrics.systemError);

    setMetrics(Object.assign({ ...metrics, ...updatedMetrics }));
  }, [statistics]);

  useEffect(() => {
    if (error) displayToast("An error occurred fetching statistics.", "error");
  }, [error]);

  const handleFilterChange = (dateFilter) => {
    var from;
    if (parseInt(dateFilter) > 1) {
      from = moment().utc().subtract(dateFilter, "days").startOf("day");
    } else {
      from = moment().utc().subtract(24, "hours");
    }
    setDateRange(
      Object.assign({
        ...dateRange,
        from,
      })
    );
    setDateFilter(dateFilter);
  };

  const displayErrorMessage = () => {
    return (
      !isFetching && (
        <>
          <div className="ampFlexCenter ampMarginSmall">
            <Button variant="outline-primary" size="lg" onClick={() => getStatistics()}>
              Refresh
            </Button>
          </div>
        </>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{"Dashboard | AMP"}</title>
      </Helmet>
      {error ? displayErrorMessage() : null}
      <div className="ampScreenContainer">
        <div id="rangeFilterDiv">
          <select
            className="ampFormSelect"
            name="platform"
            onChange={(e) => handleFilterChange(e.target.value)}
            value={dateFilter}>
            <option value="1">Last 24 Hours</option>
            <option value="7">Last 7 days</option>
            <option value="14">Last 14 days</option>
          </select>
        </div>

        <div id="dashboardDiv">
          <CountStatistic
            loading={isFetching}
            heading={"Total Sessions"}
            count={metrics.totalSessions}
            icon={<TotalSessionsIcon color={colours.sessions} height={30} width={30} />}
          />
          <CountStatistic
            loading={isFetching}
            heading={"Pin Blocks"}
            count={metrics.totalPinBlocks}
            icon={<TotalPinBlocksIcon color={colours.pinBlocks} height={30} width={30} />}
          />
          <CountStatistic
            loading={isFetching}
            heading={"Business Errors"}
            count={metrics.totalBusinessErrors}
            icon={<BusinessErrorIcon color={colours.businessErrrors} height={30} width={30} />}
          />
          <CountStatistic
            loading={isFetching}
            heading={"System Errors"}
            count={metrics.totalSystemErrors}
            icon={<SystemErrorIcon color={colours.systemErrors} height={30} width={30} />}
          />

          <div style={{ gridColumn: "span 4" }}>
            {
              <LineChart
                height={400}
                heading={"Session Statistics"}
                data={metrics.totalHourlyStatistics}
                dataKey="key"
                valueConfig={sessionStatisticsConfig}
              />
            }
          </div>
          <div style={{ gridColumn: "span 2" }}>
            {
              <BarChart
                height={400}
                heading={"Pin Blocks Generated"}
                data={metrics.pinblocksByPlatform}
                dataKey="key"
                valueConfig={pinBlockGeneratedConfig}
              />
            }
          </div>
          <div style={{ gridColumn: "span 2" }}>
            {<PieChart height={400} heading={"Platforms Usage"} data={metrics.platformUsage} valueConfig={platformsConfig} />}
          </div>

          <div style={{ gridColumn: "span 4" }}>
            <Map heading={"Usage Locations"} height={700} data={tempGeoLocationData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

const colours = {
  sessions: "#1ca6d6",
  pinBlocks: "#168000",
  businessErrrors: "#FDA500",
  systemErrors: "#FB0000",
  android: "#8bbe1b",
  ios: "#DB4436",
};

const sessionStatisticsConfig = [
  { name: "Sessions", dataKey: "totalSessions", stroke: colours.sessions },
  { name: "Pin Blocks", dataKey: "totalPinBlocks", stroke: colours.pinBlocks },
  { name: "Business Errors", dataKey: "totalBusinessErrors", stroke: colours.businessErrrors },
  { name: "System Errors", dataKey: "totalSystemErrors", stroke: colours.systemErrors },
];

const pinBlockGeneratedConfig = [
  { name: "Android", dataKey: "totalPinBlocksAndroid", fill: colours.android },
  { name: "iOS", dataKey: "totalPinBlocksIos", fill: colours.ios },
];

const platformsConfig = [{ fill: colours.android }, { fill: colours.ios }];

const tempGeoLocationData = [
  { latitude: 51.73256540025445, longitude: -1.3358092771716716 },
  { latitude: 51.7214165686511, longitude: -1.1644147572878725 },
  { latitude: 51.71721400063117, longitude: -1.2082082568884593 },
  { latitude: 51.73583824510363, longitude: -1.0940424351649711 },
  { latitude: 51.74017571473442, longitude: -1.3150742602532257 },
  { latitude: 51.73496163915278, longitude: -1.0379454413532996 },
  { latitude: 51.73582333121239, longitude: -1.0939302282840453 },
  { latitude: 51.72145745285658, longitude: -1.2491630482776055 },
  { latitude: 51.72010719621805, longitude: -2.327161328951446 },
  { latitude: 51.729727610071125, longitude: -2.0691503599266818 },
];
