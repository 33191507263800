import { httpMethods } from "./util";

export function getCurrentUserDetails() {
  return {
    url: `/user`,
  };
}

export function getUsersForOrganisation(organisationId) {
  return {
    url: `userManagement/organisation/${organisationId}/users`,
  };
}

export function getUserById(organisationId, userId) {
  return {
    url: `userManagement/organisation/${organisationId}/user/${userId}`,
  };
}

export function updateUserById(organisationId, userId) {
  return {
    url: `userManagement/organisation/${organisationId}/user/${userId}`,
    options: { method: httpMethods.put },
  };
}

export function createUser(organisationId) {
  return {
    url: `userManagement/organisation/${organisationId}/user`,
    options: { method: httpMethods.post },
  };
}

export function deleteUserById(organisationId, userId) {
  return {
    url: `userManagement/organisation/${organisationId}/user/${userId}`,
    options: { method: httpMethods.delete },
  };
}

export function getPermissions(organisationId) {
  return {
    url: `userManagement/organisation/${organisationId}/permissions`,
  };
}
